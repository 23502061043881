/* This example requires Tailwind CSS v2.0+ */
import React, { useContext, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid'
import { InformationCircleIcon } from '@heroicons/react/solid'

const AppLoading = () => {
  return (
    <div className="mt-6 bg-white pt-6 shadow-sm">
      <div className="flex items-center ml-3 mb-6">
        <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        <h1 className="text-lg font-extrabold text-gray-700 tracking-tight sm:text-4xl">Loading...</h1>
      </div>
      <div className="bg-blue-50 p-4 flex">
        <div className="flex items-center">
          <div className="flex-shrink-0 inline-flex">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <h3 className="text-lg font-medium text-blue-800">Please wait... Loading time can take approximately 30 seconds.</h3>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AppLoading