import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Logo from '../assets/svgs/er-logo.svg';
import { Dispatch, State } from '../providers/state';
import { RefreshIcon, DownloadIcon } from '@heroicons/react/solid';

const Header = ({ clientName, status }) => {
    const state = useContext(State);
    const dispatch = useContext(Dispatch);
    return (
        <header className="bg-gray-800">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div className="w-full py-6 flex items-center justify-between border-b border-indigo-500 lg:border-none">
                    <div className="sm:space-x-4 flex items-end ">
                        <h1 className="text-2xl font-bold leading-7 text-yellow-500 sm:leading-9 sm:truncate">{clientName}</h1>
                        <button type="button"
                            className="flex gap-4 items-end p-1 border border-transparent rounded-full shadow-sm text-white hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => dispatch({ forceData: true })}>
                            <RefreshIcon className="h-5 w-5" aria-hidden="true" />
                            {state.forceData && <span className="text-sm">Refreshing...</span>}
                        </button>
                    </div>

                    <div className="flex items-center gap-8">

                        <Link to='/'>
                            <Logo />
                        </Link>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;