import React, { useEffect, useReducer } from 'react';
import LayoutContainer from '../layout/layout';
import { navigate } from 'gatsby-link';
import '@shopify/polaris/dist/styles.css';
import "../styles/styles.css";

export const State = React.createContext();
export const Dispatch = React.createContext();

const initialState = {
  auth: false,
  appName: "Gatsby",
  loading: true,
  route: '/',
  routeTitle: '',
  screenView: 'desktop', //mobile, tablet
  menuVisible: false,
  error: "",
  appLoaded: false,
  clientLoaded: false,
  ticketsLoaded: false,
  ledgerLoaded: false,
  forceData: true,
  clientId: null,
  gpmManaged: false,
  clientData: {},
  ledgerData: [],
  clientErrors: [],
  ticketsData: [],
  jiraErrors: []
};

function reducer(state, newState) {
  const updatedState = {
    ...state,
    ...newState,
  }
  // console.log('STATE', updatedState);
  return updatedState;
}

const StateProvider = ({ children, location }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // const location = window.location
      // const clientId = location.pathname.substring(1);
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let clientId = params.client;
      dispatch({ clientId: clientId })
    }
  }, []);

  // useEffect(() => {
  //   // if (state.clientId)
  //     // navigate('/');
  // }, [state.clientId])

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>
        <LayoutContainer>{children}</LayoutContainer>
      </Dispatch.Provider>
    </State.Provider>
  );
};

export default StateProvider;
