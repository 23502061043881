import axios from "axios";

// Do auth flow.
export const GetClient = (id) => {
  return new Promise(async (resolve, reject) => {
    await axios({
      url: process.env.GATSBY_APP_API_URL + "/client",
      method: "GET",
      params: {
        id,
      },
    })
      .then((response) => {
        // console.log(response)
        resolve(response.data.data);
      })
      .catch((error) => {
        // console.error(error)
        reject(error);
      });
  });
};

export const GetTickets = (id) => {
  return new Promise(async (resolve, reject) => {
    await axios({
      url: process.env.GATSBY_APP_API_URL + "/tickets",
      method: "GET",
      params: {
        id,
      },
    })
      .then((response) => {
        console.log("airtable-response", response);
        resolve(response.data.data);
      })
      .catch((error) => {
        // console.error(error)
        reject(error);
      });
  });
};

export const GetLedger = (id) => {
  return new Promise(async (resolve, reject) => {
    await axios({
      url: process.env.GATSBY_APP_API_URL + "/ledger",
      method: "GET",
      params: {
        id,
      },
    })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        // console.error(error)
        reject(error);
      });
  });
};
