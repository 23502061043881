import React, { useEffect, useContext } from "react"
import { State, Dispatch } from './state';
import moment from 'moment'
import axios from 'axios';
import { collection, onSnapshot, where, query } from "firebase/firestore";
import { navigate, Link } from 'gatsby'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import union from 'lodash/union';
import { GetAdmanage, GetLedger, GetClient, GetTickets, GetTopups } from "../helpers/airtable";
import { get } from "lodash";

const localStorageActive = () => {
  if (typeof localStorage !== 'undefined') {
    try {
      localStorage.setItem('feature_test', 'yes');
      if (localStorage.getItem('feature_test') === 'yes') {
        localStorage.removeItem('feature_test');
        // console.log("localStorage is enabled")
        return true
      } else {
        // console.log("localStorage is disabled")
        return false
      }
    } catch (e) {
      // console.log("localStorage is disabled")
      return false
    }
  } else {
    // console.log("localStorage is not available")
    return false
  }
}


const AirtableProvider = (props) => {
  const state = useContext(State);
  const dispatch = useContext(Dispatch);

  // const GetAirtableClientIdCache = (clientId) => {
  //   let clientIdCache = ""
  //   if (localStorageActive()) {
  //     clientIdCache = localStorage["airtable_clientId_last"];
  //     if (clientIdCache) {
  //       console.log("Using last cached ID")
  //       clientIdCache = JSON.parse(clientIdCache);
  //     }
  //   }
  //   else
  //     console.log("LOCAL STORAGE NOT AVAILABLE")
  //   if (!clientIdCache)
  //     console.log("No cached ID found, Using URL")

  //   return clientIdCache
  // }

  // const SetAirtableCache = (clientId, ticketsData, ledgerData) => {
  //   localStorage.setItem("airtable_clientId_last", JSON.stringify(clientId));
  //   return false;
  // }

  const BillingList = async (clientId = "", force = false) => {
    console.log("API - Getting Billing List")

    // Check if there is a cache for the passed in client ID
    // let clientIdCache = GetAirtableClientIdCache(clientId);

    // Set the ID to the URL or cache.
    var id = clientId

    // Error if no ID is found
    if (typeof id === "undefined" || id === "") {
      console.error("No Client ID Found!")
      dispatch({ appLoaded: true, error: "Missing Client ID" })
    }
    else {
      console.log("Client ID Found:", id);

      dispatch({ forceData: false, gpmManaged: false, clientLoaded: false, clientData: [], ledgerLoaded: false, ledgerData: [], ticketsLoaded: false, ticketsData: [] })

      GetClient(id).then(clientData => {
        let managed = get(clientData, 'GPM Managed?', false)
        dispatch({ gpmManaged: managed === "TRUE", clientLoaded: true, clientData })
      })

      GetLedger(id).then(ledgerData => {
        dispatch({ ledgerLoaded: true, ledgerData, })
      })

      GetTickets(id).then(ticketsData => {
        let filteredTickets = ticketsData.tickets?.length > 0 ? {
          tickets: ticketsData.tickets
            .filter(({ Status }) => Status !== 'Ready for Estimate' && Status !== 'Cost Added' && Status !== 'Ready to Action')
        } : []
        dispatch({ ticketsLoaded: true, ticketsData: filteredTickets, })
      })
    }
  }


  useEffect(() => {
    // if (state.clientId  || state.forceData)
    if (state.clientId !== null && state.forceData === true)
      BillingList(state.clientId, state.forceData)
  }, [state.clientId, state.forceData])
  return (
    <>
      {props.children}
    </>
  );
}

export default AirtableProvider;