import React, { useState, useEffect, useContext } from "react"
import { State, Dispatch } from '../providers/state';
import LoadingApp from "../components/loadingApp"
import AirtableProvider from "../providers/airtable"
import Header from './Header'
import { Helmet } from 'react-helmet';
import { XCircleIcon } from '@heroicons/react/solid'

const AppLayout = (props) => {
  const state = useContext(State);
  const dispatch = useContext(Dispatch);
  const { ['Client Name (PK)']: clientName, Status } = state.clientData;

  const renderError = () => {
    if (state.error) {
      return (
        <div className="rounded-md bg-red-50 p-4 mt-6 shadow-sm">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-xl font-medium text-red-800"><span>ERROR: {state.error}</span></h3>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderAppLayout = (data) => {
    let content = ''
    if (!state.clientLoaded && !state.appLoaded) {
      content = <LoadingApp />
    } else if (state.appLoaded) {
      content = renderError();
    }
    else {
      content = props.children
    }
    return content
  };
  
  return (
    <div id='gatsby-app'>
      <Helmet title="Billing Portal" />
      <Header clientName={clientName} status={Status} />
      <main className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-8">
        <AirtableProvider>
          {renderAppLayout()}
        </AirtableProvider>
      </main>
    </div>
  );
}

export default AppLayout
